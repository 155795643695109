/**
 * Spin in het Web Apeldoorn
 * Created by Jelmer on 12-3-2017.
 *
 * Gebruiker binnen een domein of een project.
 */

angular.module('dl.project', [
    'sihw.sihwlog',
    'dl.approuter',
    'dl.constants',
    'dl.api',
    'sihw.pickclass.directive',
    'sihw.alert.service',
    'sihw.confirm'
]).config(['approuterProvider', function (approuterProvider) {
    approuterProvider.state('project',
        {
            url: '/project/:project',
            templateUrl: 'states/project/project.html',
            controller: 'ProjectController',
            params: {
                nieuw: false //door domeincontroller op true meegestuurd na aanmaken nieuw project - wij openen dan de wijzigdialoog
            }
        })

}]).controller('ProjectController', ['$scope', '$stateParams', '$uibModal', '$translate', 'approuter', 'sihwlog', 'sihwconfirm', 'sihwalert', 'api', '__', 'EMAIL_REGEX', 'FRONTEND_URL', function ($scope, $stateParams, $uibModal, $translate, approuter, sihwlog, sihwconfirm, sihwalert, api, __, EMAIL_REGEX, FRONTEND_URL) {
    var log = sihwlog.logLevel('debug');

    log.log('ProjectController', $stateParams);
    var project = $stateParams.project;
    var nieuwproject = $stateParams.nieuw;

    approuter.menutitel("VIEWTYPE.EDITPROJECT");

    $scope.FRONTEND_URL = FRONTEND_URL; //voor doorklikken

    //hebben we ui in de localstorage?
    var ui = {
        tab: 'model',
        model: {
            order: '+titel'
        },
        gebruiker: {
            order: '+naam'
        }
    };

    initScopeUi();
    initproject();

    /**
     * Haal de projectinfo (opnieuw) op
     */
    function initproject() {
        api.projectdata(project)
            .then(function (info) {
                log.debug('project', info);
                $scope.project = info.project;
                $scope.project.nummodellen = info.modellen.length;
                $scope.project.numgebruikers = Object.keys(info.users).length;
                $scope.modellen = info.modellen;
                $scope.modelhash = {};
                for (let m of $scope.modellen) {
                    $scope.modelhash[m.idmodel] = m;
                }
                $scope.gebruikers = info.users; //hash

                //bepaal displaynaam
                angular.forEach($scope.gebruikers, function (u) {
                    u.naam = u.displaynaam || u.username;
                    u.orig = Object.assign({}, u);
                });

                angular.forEach($scope.modellen, function (model) {
                    //zet even de usernaam
                    model.gebruiker = $scope.gebruikers[model.user_id].naam;
                    model.origtemplate = model.template; //bewaren
                    model.orignorm = model.norm;
                });

                //zijn we superuser?
                $scope.superadmin = $scope.basis.userdata.caps.superadmin;
                //zijn we domeinadmin van dit domein?
                $scope.domeinadmin = ($scope.superadmin || ($scope.basis.userdata.caps.domeinadmin.indexOf($scope.project.domeinid) !== -1));
                approuter.menudomein($scope.project.domeinid);
                approuter.menutitel("VIEWTYPE.EDITPROJECT", $scope.project.code);
                approuter.menuproject($scope.project);

                //moeten we de wijzigdialoog openen?
                if (nieuwproject && $scope.domeinadmin) {
                    nieuwproject = false; //eenmalig
                    wijzigProject(true); //uitvoeren
                }

            })
            .catch(function (err) {
                log.warn(err);
                approuter.mainScreen(); //whatever
            })
    }

    /**
     * Haal de ui-info uit de localstorage en zet het in de scope
     */
    function initScopeUi() {
        try {
            if (localStorage['projectui_' + project]) {

                var newui = JSON.parse(localStorage['projectui_' + project]);
                log.debug('** ui uit storage', newui);
                angular.extend(ui, newui);
            }
        } catch (_e) {
        }
        $scope.ui = ui; //ook in scope

        $scope.$watch('ui', function () {
            try {
                //sla de nieuwe versie op
                log.debug('*** saving ui');
                localStorage['projectui_' + project] = JSON.stringify($scope.ui);
            } catch (_e) {

            }
        }, true);

    }

    $scope.changeOrder = function (panel, veld) {
        //gewijzigd: we werken gewoon met één order veld, geen ordering binnen ordering

        var p = ui[panel];
        if (p.order === '+' + veld) {
            p.order = '-' + veld;
        } else {
            p.order = '+' + veld; //hoe dan ook
        }
    };

    $scope.setTemplate = function (model) {
        //zet het model op template true of false, gegeven het model
        api.setModelBool(model.idmodel, 'template', model.template).then(function (template) {
            model.template = model.origtemplate = template;
        }).catch(function () {
            model.template = model.origtemplate; //terugzetten
        });
    };

    $scope.setNorm = function (model) {
        //zet het model op norm true of false, gegeven het model
        api.setModelBool(model.idmodel, 'norm', model.norm).then(function (norm) {
            model.norm = model.orignorm = norm;
        }).catch(function () {
            model.norm = model.orignorm; //terugzetten
        });
    };

    $scope.setActief = function (user) {
        //zet de uer gebruiker als actief voor het huidige project
        log.debug("Gebruiker " + user.iduser + " actief voor " + project + "?", user.actief);
        api.setProjectActief(user.iduser, project, user.actief).then(function (actief) {
            user.actief = user.orig.actief = actief;
        }).catch(function () {
            user.actief = user.orig.actief; //terugzetten
        });
    };

    $scope.setMeekijker = function (user) {
        //zet de uer gebruiker als actief voor het huidige project
        log.debug("Gebruiker " + user.iduser + " meekijken voor " + project + "?", user.meekijker);
        api.setMeekijker(user.iduser, project, user.meekijker).then(function (meekijker) {
            user.meekijker = user.orig.meekijker = meekijker;
        }).catch(function () {
            user.meekijker = user.orig.meekijker; //terugzetten
        });
    };

    $scope.setProjectadmin = function (user) {
        //zet de user gebruiker als projectadmin voor het huidige project
        log.debug("Gebruiker " + user.iduser + " projectadmin voor " + project + "?", user.projectadmin);
        api.setProjectadmin(user.iduser, project, user.projectadmin).then(function (admin) {
            user.projectadmin = user.orig.projectadmin = admin;
        }).catch(function () {
            user.projectadmin = user.orig.projectadmin; //terugzetten
        });
    };

    $scope.setDocent = function (user) {
        //zet de user gebruiker als docent voor het huidige project
        log.debug("Gebruiker " + user.iduser + " docent voor " + project + "?", user.projectadmin);
        api.setDocent(user.iduser, project, user.docent).then(function (docent) {
            user.docent = user.orig.docent = docent;
        }).catch(function () {
            user.docent = user.orig.docent; //terugzetten
        });
    };
    /**
     * Verwijder de gebruiker uit het project, met modellen en al
     * @param user
     */
    $scope.verwijderGebruiker = function (user) {
        //feedback vragen
        sihwconfirm(
            __("PROJECT.VERWIJDERGEBRUIKER"),
            __(user.modellen ? "PROJECT.VERWIJDERGEBRUIKERTEKSTMETMODELLEN" : "PROJECT.VERWIJDERGEBRUIKERTEKST", {
                naam: user.naam,
                modellen: user.modellen
            }), __('BTN_JA'), __('BTN_NEE')
        ).then(function (ok) {
            if (!ok) {
                return; //geannuleerd
            }
            api.gebruikerUitProject(user.userproject).then(function () {
                initproject(); //opnieuw alles inlezen
            }).catch(function (err) {
                sihwalert("PROJECT.VERWIJDERGEBRUIKER_ERROR.TITEL", err.code ? "PROJECT.VERWIJDERGEBRUIKER_ERROR." + err.code : err.msg);
            });

        });
    };

    /**
     * Wijzig project. Als bijnieuw true, dan aangeroepen nadat user een nieuw project heeft gemaakt. Heeft wat consequenties voor de layout
     * @param bijnieuw
     */
    function wijzigProject(bijnieuw) {
        //toegang al geregeld
        $uibModal.open({
            animation: true,
            backdrop: 'static',
            windowClass: 'project_wijzigproject',
            scope: $scope, //scope wordt een kind van de onze, handig voor projectinfo
            templateUrl: '/states/project/wijzigproject.html',
            controller: [
                '$scope', '$uibModalInstance', 'sihwalert',
                function ($scope, $uibModalInstance, sihwalert) {
                    $scope.model = angular.copy($scope.project); //in $scope.project dus het origineel
                    $scope.bijnieuw = bijnieuw; //voor het template
                    $scope.inlogcode_geldigheid = '1d'; //default nieuwe inlogcode
                    let allkeys = $translate.getAvailableLanguageKeys();
                    let currentlang = $scope.model.stuurgegevens = $translate.preferredLanguage(); //standaard mailen bij gewijzigde naam
                    $scope.options = {
                        stuurgegevens: [
                            {
                                label: $translate.instant('PROJECT.WIJZIG.STUURGEGEVENS.GEENACTIE'),
                                lang: '-'
                            },
                            {
                                label: $translate.instant('PROJECT.WIJZIG.STUURGEGEVENS.MAIL', {lang: currentlang.toUpperCase()}),
                                lang: currentlang
                            }
                        ]
                    };
                    allkeys.forEach(function (langkey) {
                        if (langkey === currentlang) {
                            return; //door maar weer
                        }
                        $scope.options.stuurgegevens.push({
                            label: $translate.instant('PROJECT.WIJZIG.STUURGEGEVENS.MAIL', {lang: langkey.toUpperCase()}),
                            lang: langkey
                        });
                    });

                    //relevante modellen als standaardmodel
                    $scope.sjabloonofnorm = $scope.modellen.filter(model => model.norm || model.template);

                    log.debug($scope.model);
                    $scope.dialog = $uibModalInstance;

                    /**
                     * Maak een nieuwe inlogcode - de code zelf is nog niet beschikbaar natuurlijk
                     */
                    $scope.maakInlogcode = function () {
                        $scope.model.project_codelogins ||= [];
                        let einddatum = moment();
                        switch ($scope.inlogcode_geldigheid) {
                            case '1u':
                                einddatum.add(1, 'hour');
                                break;
                            case '4u':
                                einddatum.add(4, 'hour');
                                break;
                            case '1d':
                                einddatum.add(1, 'day');
                                break;
                            case '1w':
                                einddatum.add(1, 'week');
                                break;
                            case '1m':
                                einddatum.add(1, 'month');
                                break;
                            default:
                                einddatum = null;
                                break;
                        }
                        $scope.model.project_codelogins.push({
                            code: '?',
                            geldigtot: einddatum ? einddatum.format('YYYY-MM-DD HH:mm') : null
                        });
                        //dirty form
                        $scope.wijzigproject.$setDirty();
                    }

                    /**
                     * Verwijder / terugzetten van een project_codelogin
                     * @param pcl
                     */
                    $scope.toggleInlogcode = function (pcl) {
                        //dirty form
                        $scope.wijzigproject.$setDirty();
                        if (!pcl.id) {
                            //deze is toegevoegd en kan weer weg
                            $scope.model.project_codelogins.splice($scope.model.project_codelogins.indexOf(pcl), 1);
                            return;
                        }
                        //deze bestond al. Toggle de verwijderd flag
                        pcl.verwijderd = !pcl.verwijderd;
                    }

                    $scope.opslaan = function () {
                        //opslaan volgens model, maar niet alles
                        let opslaan = angular.copy($scope.model);
                        delete opslaan.stuurgegevens;
                        api.wijzigproject(opslaan, //inclusief id en stuur
                            opslaan.code !== $scope.project.code && $scope.model.stuurgegevens !== '-' && $scope.model.stuurgegevens)
                            .then(function () {
                                initproject();
                                $uibModalInstance.close();
                            }).catch(function (err) {
                            sihwconfirm(__("PROJECT.WIJZIG.ERROR.TITEL"), err.code ? __("PROJECT.WIJZIG.ERROR." + err.code) : err.msg, __("BTN_CONFIRM"), false);
                        });
                    };
                }]
        }).result.then(function () {
        })
            .catch(function () { //noop
            });
    }

    //scope variant
    $scope.wijzigproject = function () {
        wijzigProject(false);
    };

    /**
     * Kopieerproject dispatch voor domeinadmin en superadmin
     */
    $scope.kopieerproject = function () {
        if ($scope.superadmin) {
            kopieerproject_superadmin();
        } else {
            kopieerproject_domeinadmin();
        }
    }

    /**
     * Kopieerproject: uitgebreide versie voor superadmin
     */
    function kopieerproject_superadmin() {
        $uibModal.open({
            animation: true,
            backdrop: 'static',
            windowClass: 'project_kopieerproject',
            scope: $scope, //kind van onze
            templateUrl: '/states/project/kopieerproject_superadmin.html',
            controller: [
                '$scope', '$uibModalInstance',
                function ($scope, $uibModalInstance) {
                    $scope.dialog = $uibModalInstance;
                    //paar dingen in model gestopt, moet eigenlijk alles uit de ux, vanwege subscopes in ng-if e.d.
                    //gewoon doen als er iets wijzigt aan een veld (of het veld werkt niet)
                    $scope.model = {
                        heeftWeesmodel: false,
                        weesmodeleigenaar: null,
                        nieuwdomein: $scope.project.domeinid, //standaard dezelfde
                        geselecteerdeGebruikers: [] //wordt gevuld in updatewees
                    };
                    $scope.nieuwproject = `${$scope.project.code} (${__('COPY')})`; //standaard hetzelfde
                    $scope.tab = "basis";

                    //we gaan de modellen even goed zetten
                    const kopieerbaar = $scope.modellen.filter(m => m.samenwerking !== 'slave');
                    $scope.modellijst = {
                        standaard: $scope.project.standaardmodel ? kopieerbaar.find(m => m.idmodel === $scope.project.standaardmodel) : null,
                        norm: kopieerbaar.filter(m => m.norm),
                        template: kopieerbaar.filter(m => m.template), //kan dubbel zijn
                        normaal: kopieerbaar.filter(m => !(m.norm || m.template))
                    };
                    //sorteren
                    for (let key of ['norm', 'template', 'normaal']) {
                        $scope.modellijst[key].sort((a, b) => a.titel.localeCompare(b.titel));
                    }

                    $scope.modelselectie = {}; //welke zijn geselecteerd?
                    if ($scope.project.standaardmodel) {
                        $scope.modelselectie[$scope.project.standaardmodel] = true;
                    }
                    for (let m of [...$scope.modellijst.norm, ...$scope.modellijst.template]) {
                        $scope.modelselectie[m.idmodel] = true;
                    }

                    let gebruikers = Object.values($scope.gebruikers);
                    gebruikers.sort((a, b) => a.naam.localeCompare(b.naam));
                    $scope.gebruikerslijst = {
                        admins: gebruikers.filter(g => g.domeinadmin || g.projectadmin || g.superadmin),
                        docenten: gebruikers.filter(g => g.docent),
                        normaal: gebruikers.filter(g => !(g.domeinadmin || g.projectadmin || g.superadmin || g.docent))
                    };

                    $scope.gebruikerselectie = {}; //welke zijn geselecteerd?
                    for (let g of [...$scope.gebruikerslijst.admins, ...$scope.gebruikerslijst.docenten]) {
                        $scope.gebruikerselectie[g.userproject] = true;
                    }

                    //wat metainformatie over geselecteerde gebruikers
                    $scope.gebruikerGeselecteerdViaModel = {}; //gebruikersid op boolean, voor de snelheid
                    log.debug($scope.modelselectie);
                    for (let idmodel of Object.keys($scope.modelselectie).filter(idmodel => $scope.modelselectie[idmodel])) {
                        //dit zijn de geselecteerde modellen
                        log.debug(idmodel, $scope.modelhash[idmodel]);
                        $scope.gebruikerGeselecteerdViaModel[$scope.modelhash[idmodel].userproject_id] = true;
                    }
                    //en is er nu een weesmodel?
                    updateModelEnUserinformatie();

                    //moet dus bij change opnieuw gedaan worden:
                    $scope.modelGeselecteerd = function (idmodel) {
                        //de huidige waarde in modelselectie klopt al
                        let userproject_id = $scope.modelhash[idmodel].userproject_id;
                        $scope.gebruikerGeselecteerdViaModel[userproject_id] = $scope.modellen.some(m => m.userproject_id === userproject_id && $scope.modelselectie[m.idmodel]);
                        updateModelEnUserinformatie(); //bijwerken
                    }

                    /**
                     * Update de flag dat er modellen zijn zonder eigenaar. Aangeroepen bij wijziging selectie model of gebruiker
                     */
                    function updateModelEnUserinformatie() {
                        $scope.model.heeftWeesmodel = gebruikers.some(user => $scope.gebruikerGeselecteerdViaModel[user.userproject] && (!$scope.gebruikerselectie[user.userproject]));
                        //en werk de gebruikers bij
                        $scope.model.geselecteerdeGebruikers = gebruikers.filter(user => $scope.gebruikerselectie[user.userproject]);
                        log.debug($scope.model.geselecteerdeGebruikers)
                    }

                    /**
                     * Er is iets gewijzigd in de gebruikersselectie. We moeten opnieuw kijken of er weesmodellen zijn
                     */
                    $scope.gebruikerGeselecteerd = function () {
                        updateModelEnUserinformatie();
                        if ($scope.model.heeftWeesmodel && $scope.model.weesmodeleigenaar && (!$scope.gebruikerselectie[$scope.model.weesmodeleigenaar])) {
                            //reset de weesmodeleigenaar
                            log.debug(`Reset weesmodeleigenaar`);
                            $scope.model.weesmodeleigenaar = null;
                        }
                        log.debug(`Weesmodeleigenaar nu`, $scope.model.weesmodeleigenaar);
                    }

                    $scope.uitvoeren = function () {
                        api.kopieerproject($scope.project.id, $scope.nieuwproject,
                            Object.keys($scope.modelselectie).filter(idmodel => $scope.modelselectie[idmodel]),
                            $scope.model.geselecteerdeGebruikers.map(user => user.userproject),
                            $scope.model.weesmodeleigenaar,
                            $scope.model.nieuwdomein).then(res => {
                            $uibModalInstance.close();
                            api.toast("PROJECT.KOPIEER.GEKOPIEERD");
                            approuter.go('project', {project: res.project});
                        }).catch(e => {
                            if (e.code === "DOUBLE_DATA") {
                                api.toast('PROJECT.KOPIEER.DUBBELENAAM'); //en gewoon door
                            } else {
                                log.error(e);
                            }
                        });
                    };
                }
            ]
        }).result.then(angular.noop, angular.noop);
    }

    /**
     * Kopieerproject: eenvoudige versie voor domeinadmins
     */
    function kopieerproject_domeinadmin() {
        $uibModal.open({
            animation: true,
            backdrop: 'static',
            windowClass: 'project_kopieerproject',
            scope: $scope, //kind van onze
            templateUrl: '/states/project/kopieerproject_domeinadmin.html',
            controller: [
                '$scope', '$uibModalInstance',
                function ($scope, $uibModalInstance) {
                    $scope.dialog = $uibModalInstance;
                    $scope.nieuwproject = `${$scope.project.code} (${__('COPY')})`; //standaard hetzelfde

                    $scope.uitvoeren = function () {
                        api.kopieerproject_simpel($scope.project.id, $scope.nieuwproject,
                            !! $scope.maakcode).then(res => {
                            $uibModalInstance.close();
                            api.toast("PROJECT.KOPIEER.GEKOPIEERD");
                            approuter.go('project', {project: res.project});
                        }).catch(e => {
                            if (e.code === "DOUBLE_DATA") {
                                api.toast('PROJECT.KOPIEER.DUBBELENAAM'); //en gewoon door
                            } else {
                                log.error(e);
                            }
                        });
                    };
                }
                ]}).result.then(angular.noop, angular.noop);
    }

    /**
     * Superadmin: verplaats het project naar een ander domein, met wat opties
     */
    $scope.migreerproject = function () {
        log.debug(`domeinen`, $scope.basis.domeinen);
        $uibModal.open({
            animation: true,
            backdrop: 'static',
            windowClass: 'project_migreerproject',
            scope: $scope, //kind vna onze
            templateUrl: '/states/project/migreerproject.html',
            controller: [
                '$scope', '$uibModalInstance',
                function ($scope, $uibModalInstance) {
                    $scope.dialog = $uibModalInstance;
                    $scope.nieuwenaam = $scope.project.code; //standaard hetzelfde
                    $scope.checkVerwijderUser = true; //standaard users die over zijn verwijderen

                    $scope.uitvoeren = function () {
                        //de uitvoerknop
                        sihwconfirm('Migratie', "Dit is een ingrijpende actie. Gebruikers worden gekopiëerd en/of verplaatst. Weet je zeker dat je dit wilt doen?").then(ok => {
                            if (!ok) {
                                return;
                            }
                            log.warn('Daar gaan we', $scope.project.id, $scope.nieuwdomein.id, $scope.nieuwenaam, $scope.checkVerwijderUser);
                            api.migreerproject($scope.project.id, $scope.nieuwdomein.id, $scope.nieuwenaam, $scope.checkVerwijderUser).then(res => {
                                log.debug(res);
                                initproject();
                                $uibModalInstance.close();
                                sihwalert("Gemigreerd", res.rapport.join("<br>"));
                            });
                        });
                    }
                }
            ]
        }).result.then(angular.noop, angular.noop);
    }

    /**
     * Verwijder het project. Per ui al afgedwongen dat dit alleen kan als er geen users zijn en domeinadmin / superadmin only
     * (projectadmin kan zichzelf niet verwijderen, althans de ui verbiedt het, achterkant niet)
     */
    $scope.verwijderProject = function () {
        //wel even vragen
        sihwconfirm(__("PROJECT.VERWIJDER.CONFIRM"), __("PROJECT.VERWIJDER.TEKST"), __('BTN_JA'), __('BTN_NEE')).then(function (ok) {
            if (ok) {
                //nog een keer
                sihwconfirm(__("PROJECT.VERWIJDER.CONFIRM2"), __("PROJECT.VERWIJDER.TEKST2"), __('PROJECT.VERWIJDER.BTN_DOORGAAN'), __('PROJECT.VERWIJDER.BTN_STOP'), null, 20000).then(function (ok) {
                    if (ok) {
                        api.verwijderProject(project).then(function () {
                            approuter.go('domein', {domein: $scope.project.domeinid});
                        }).catch(function (err) {
                            sihwconfirm(__("PROJECT.VERWIJDER.ERROR.TITEL"), err.code ? __("PROJECT.VERWIJDER.ERROR." + err.code) : err.msg, __("BTN_CONFIRM"), false);
                        });
                    }
                });
            }
        });
    };

    $scope.gebruikerstoevoegen = function () {
        //uitgangspunt: we mogen dit doen, anders zijn we hier niet

        $uibModal.open({
            animation: true,
            backdrop: 'static',
            windowClass: 'project_addusers',
            scope: $scope, //scope wordt een kind van de onze, handig voor projectinfo
            templateUrl: '/states/project/gebruikerstoevoegen.html',
            // size: 'lg',
            controller: [
                '$scope', '$uibModalInstance', 'sihwalert',
                function ($scope, $uibModalInstance, sihwalert) {
                    //in de dialoog
                    $scope.model = {
                        stuurgegevens: '-',
                        maildomein: '',
                        gebruikerskeuze: [],
                        usertoevoegen: {
                            email: '',
                            displaynaam: ''
                        }
                    };
                    var usersopnaam = {};

                    /**
                     * Haal de gebruikers op waaruit gekozen kan worden. Gebeurd na opslaan eventueel opnieuw
                     */
                    function updateGebruikerskeuze() {
                        return api.projectGebruikersKeuze(project).then(
                            function (users) {
                                $scope.model.gebruikerskeuze = users;
                                //en refmapping op gebruikersnaam, voor het gemak
                                usersopnaam = {};
                                angular.forEach(users, function (user) {
                                    usersopnaam[user.username.toLowerCase()] = user;
                                });
                            }
                        );
                    }

                    updateGebruikerskeuze();

                    $scope.ui = {
                        bestaandeusers: true
                    };
                    $scope.options = {};
                    //bepaal de opties voor resetwachtwoord etc
                    var allkeys = $translate.getAvailableLanguageKeys();
                    var currentlang = $scope.model.stuurgegevens = $translate.preferredLanguage(); //verplicht, dus dit is de keuze
                    log.debug(`currentlang`, currentlang);

                    $scope.options.stuurgegevens = [
                        //het is verplicht, dus geen doe-niets meer
                        /*   {
                               label: $translate.instant('PROJECT.ADDUSER.STUURGEGEVENS.GEENACTIE'),
                               lang: '-'
                           },*/
                        {
                            label: $translate.instant('PROJECT.ADDUSER.STUURGEGEVENS.MAIL', {lang: currentlang.toUpperCase()}),
                            lang: currentlang
                        }
                    ];
                    allkeys.forEach(function (langkey) {
                        if (langkey === currentlang) {
                            return; //door maar weer
                        }
                        $scope.options.stuurgegevens.push({
                            label: $translate.instant('PROJECT.ADDUSER.STUURGEGEVENS.MAIL', {lang: langkey.toUpperCase()}),
                            lang: langkey
                        });
                    });

                    /**
                     * Nieuwe gebruiker toegevoegd via het snelformulier: we zetten hem gewoon tussen de bestaande
                     */
                    $scope.usertoevoegen = function () {
                        var email = volemail();
                        if (!email) {
                            return; //niet geldig
                        }
                        if (usersopnaam[email]) {
                            usersopnaam[email].toegevoegd = true;
                        } else {
                            $scope.model.gebruikerskeuze.push(usersopnaam[email] = {
                                id: null,
                                username: email,
                                displaynaam: $scope.model.usertoevoegen.displaynaam,
                                nieuw: true,
                                toegevoegd: true //dit is DE flag om een user naar achter te sturen
                            });
                        }

                        //scroll naar boven
                        $('#selecteergebruikerbody').scrollTop(0);
                        //en de content weer weg
                        $scope.model.usertoevoegen = {};
                    };

                    /**
                     * Check of toevoegen goed is, voor button en voor de usertoevoegen zelf
                     */
                    $scope.checkToevoegmodel = function () {
                        return !!volemail(); //het volle mailadres, getest en al (leeg als het niet okee is)
                    };

                    /**
                     * Maak het volledige emailadres van de toe te voegen gebruiker, geeft leeg terug als dit geen geldig adres is, anders het emailadres
                     * @return {*}
                     */
                    function volemail() {
                        var email = $scope.model.usertoevoegen.email || '';
                        if (!email) {
                            return "";
                        }
                        if (!email.match(/\@/)) {
                            email += '@' + $scope.model.maildomein;
                        }
                        email = email.toLowerCase();
                        return EMAIL_REGEX.test(email) ? email : "";
                    }

                    /**
                     * Toggle de toegevoegd-status van een domeinuser
                     * @param user
                     */
                    $scope.toggleUser = function (user) {
                        //alleen als niet al in project
                        if (!user.inProject) {
                            user.toegevoegd = !user.toegevoegd;
                        }
                    };

                    /**
                     * Doe het opslaan
                     */
                    $scope.opslaan = function () {
                        //welke users moeten er gebeuren
                        //staat er nog eentje klaar?
                        $scope.usertoevoegen();

                        var verzenden = [];
                        angular.forEach($scope.model.gebruikerskeuze, function (u) {
                            if (u.toegevoegd && (!u.inProject)) //we blijven af van users die al in het project zitten
                            {
                                //mail is gecheckt bij nieuwe users, dus lets go
                                verzenden.push(u); //backend zoekt onderscheid nieuw / bestaand wel uit
                            }
                        });
                        log.debug('Verzenden', verzenden);
                        if (verzenden.length === 0) {
                            //niets te doen
                            return;
                        } else {
                            api.projectgebruikers(project, verzenden,
                                $scope.model.stuurgegevens && $scope.model.stuurgegevens !== '-' ?
                                    $scope.model.stuurgegevens : false).then(function (feedback) {
                                //feedback bevat alle gebruikers met problemen, met toegevoegd een statuscode
                                if (!(feedback && feedback.length)) {
                                    sihwalert('PROJECT.ADDUSER.TOEGEVOEGD_LABEL', 'PROJECT.ADDUSER.TOEGEVOEGD_TEKST', "BTN_CONFIRM").then(
                                        function () {
                                            $uibModalInstance.close(); //klaar
                                        }
                                    );
                                }
                                //Okee, we gaan herladen en dan de feedback toevoegen
                                updateGebruikerskeuze().then(function () {
                                    //we voegen per user de feedback toe
                                    angular.forEach(feedback, function (fb) {
                                        log.debug(fb, usersopnaam[fb.username]);
                                        fb.username = fb.username.toLowerCase();
                                        if (usersopnaam[fb.username]) {
                                            usersopnaam[fb.username].statuscode = fb.statuscode;
                                        } else {
                                            $scope.model.gebruikerskeuze.push(fb); //is gewoon een user
                                            usersopnaam[fb.username] = fb;
                                        }
                                    });
                                });
                            });
                        }
                    };

                    $scope.annuleren = function () {
                        $uibModalInstance.dismiss();
                    };

                }
            ]
        }).result.then(initproject) //opnieuw inlezen
            .catch(function () {
            }); //we doen er niets mee als er geannuleerd wordt
    };



}]);
