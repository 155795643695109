/**
 * SPIN IN HET WEB APELDOORN
 * User: Jelmer Jellema
 * Date: 8-11-2016
 * Time: 12:00
 *
 * Require alle states. Required in de app en in testmodules die over states heen redeneren
 */

angular.module('dl.states',[
    'dl.login',
    'dl.splash',
    'dl.registratie',
    'dl.domein',
    'dl.normcheck',
    'dl.project',
    'dl.gebruiker',
    'dl.aanmeldingen',
    'dl.dashboard'
]);
